import React, { useState, useEffect,  useRef} from 'react';
// import {Link} from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

function ServiceItem(props) {
    // const serviceURL = `/service/${props.title.split(' ').join('-').toLowerCase()}?id=${props.id}`

    const [isIntersecting, setIsIntersecting] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsIntersecting(entry.isIntersecting);
        },
        { rootMargin: "-70px" }
      );
      console.log(isIntersecting);
      observer.observe(ref.current);
  
      return () => observer.disconnect();
    }, [isIntersecting]);
  
    useEffect(() => {
      if (isIntersecting) {
        ref.current.querySelectorAll("div").forEach((el) => {
          el.classList.add("slide-in");
        });
      } else {
        ref.current.querySelectorAll("div").forEach((el) => {
          el.classList.remove("slide-in");
        });
      }
    }, [isIntersecting]);

    const customScrollFunction = (element) => {
        const offsetTop = 150; // Adjust this value to control the offset
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offsetTop;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    return (
        <div className="col-sm-6 col-lg-4">
            <div className="service-item">
                <figure className="service-thumb">
                    {/* <Link to={`${process.env.PUBLIC_URL + serviceURL}`}>
                        <img src={require('../../assets/img/' + props.thumb)} alt={props.title}/>
                    </Link> */}
                    {/* <Link to="/#services-items" scroll={customScrollFunction}> */}
                    <img src={require('../../assets/img/' + props.thumb)} alt={props.title} />
                    {/* </Link> */}
                    <figcaption className="service-txt">
                        <h5>{props.title}</h5>
                    </figcaption>
                </figure>
                <main ref={ref}>
                <div className="service-content">
                    <div className="service-content-inner">
                        <h5>
                            {/* <Link to={`${process.env.PUBLIC_URL + serviceURL}`} className="stretched-link">{props.title}</Link> */}
                            <Link to="/#services-items" className="stretched-link" scroll={customScrollFunction}>{props.title}</Link>
                        </h5>
                        <p>{props.text}</p>
                    </div>
                </div>
                </main>
            </div>
        </div>
    );
}

export default ServiceItem;
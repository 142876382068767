import React from 'react';
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import {Link} from "react-router-dom";
import Logo from '../../assets/img/logo-dark.png'

function Footer() {
    return (
        <footer className="footer-area sp-bottom">
            <div className="container">
                <div className="row mtn-40">
                    <div className="col-lg-4 order-4 order-lg-0">
                        <div className="widget-item">
                            <div className="about-widget">
                                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                                    <img src={Logo} alt="Logo"/>
                                </Link>

                                {/* <Text>
                                    Every idea turn to be true.
                                </Text> */}

                                <Text classes="copyright-txt">
                                    &copy; {new Date().getFullYear()} Hashtera. All Rights Reserved.
                                </Text>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Information">
                            <List classes="widget-list">
                                <LI><Link to={`${process.env.PUBLIC_URL + "/about"}`}>Our company</Link></LI>
                                <LI><Link to={`${process.env.PUBLIC_URL + "/blog"}`}>Blog</Link></LI>
                                {/* <LI><Link to={`${process.env.PUBLIC_URL + "/services"}`}>Our services</Link></LI> */}
                                {/* <LI><Link to={`${process.env.PUBLIC_URL + "/"}`}>Careers</Link></LI> */}
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">

                                {/* <a className="reddit" href={`http://reddit.com/submit?url=${window.location.href}&title=${post.title}`}>
                                    <i className="fa-brands fa-reddit"/>
                                     </a>*/}
                               
                                <LI><a href={`https://x.com/joinhashtera`}>Twitter</a></LI>
                                <LI><a  href={`https://linkedin.com/company/hashtera`}>Linkedin</a></LI>
                            </List>
                        </Widget>
                    </div>

                    <div className="col-md-4 col-lg-3">
                        <Widget title="Contact Us">
                            <address>
                                contact@hashtera.com<br/>
                            </address>
                        </Widget>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
import React from 'react';
// import { Link } from "react-router-dom";

const TeamMember = ({ id, profilePic, name, designation, linkedin }) => {
    // const teamMemberURl = `/team-member/${name.split(' ').join('-').toLocaleLowerCase()}?id=${id}`;
    return (
        <div className="col-sm-6 col-lg-12">
            <div className="team-mem-item team-mem-item--2">
                <figure className="member-pic">
                    <a href={`https://linkedin.com/in/${linkedin}`} target="_blank" rel="noopener noreferrer" className="linkedin"><img src={require('../../../assets/img/' + profilePic)} alt={name} /></a>

                    {/* <Link to={`${process.env.PUBLIC_URL + teamMemberURl}`}>
                        <img src={require('../../../assets/img/' + profilePic)} alt={name}/>
                    </Link> */}
                </figure>
                <div className="member-info">
                    <h5><a href={`https://linkedin.com/in/${linkedin}`} target="_blank" rel="noopener noreferrer" className="linkedin">{name}</a></h5>
                    {/* <h5><Link to={`${process.env.PUBLIC_URL + teamMemberURl}`}>{name}</Link></h5> */}
                    <span className="designation">{designation}</span>
                </div>
            </div>
        </div>
    );
};

export default TeamMember;
import React from 'react';
import { Link } from "react-router-dom";
import navbarButtonData from "../../data/Navbar/button";
const HeaderConfig = () => {

    const MobileMenuHandler = () => {
        const offCanvasMenu = document.querySelector('.off-canvas-menu');
        offCanvasMenu.classList.add('active');
    }

    return (
        <div className="header-action mt-lg-3 ">
            {/* <a href="tel:00199823568658" className="tel-no">+998 23568 658</a> */}
            <Link target="_blank" rel="noopener noreferrer" to={`${process.env.PUBLIC_URL + navbarButtonData.btnLink}`} className="btn btn-nav btn-cog tel-no">{navbarButtonData.btnText}</Link>
            {/* <button onClick={LoginRegHandler} className="btn-cog"><i className="fa fa-cog"/></button> */}
            <button onClick={MobileMenuHandler} className="btn-menu d-lg-none float-right"><i className="fa fa-bars"/></button>
        </div>
    );
}

export default HeaderConfig;
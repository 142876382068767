import React from 'react';
import SlickSlider from '../../UI/Slick'
import SliderData from '../../../data/Slider/home-1'
import { Link } from "react-router-dom";


const NextArrow = ({ className, onClick }) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-right" /></button>
    )
};

const PrevArrow = ({ className, onClick }) => {
    return (
        <button className={className} onClick={onClick}><i className="fa fa-angle-left" /></button>
    )
};

const Slider = () => {
    const settings = {
        arrows: true,
        dots: false,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    };

    return (
        <div className={'slider-area'}>
            <SlickSlider settings={settings}>
                {
                    SliderData.map(item => (
                        <div key={item.id}>
                            <div className="slider-item"
                                //entire image white blur shade 
                                //  style={{backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3)),url(${require('../../../assets/img/' + item.bg)})`  }}
                                // angled white shade 
                                //   style={{backgroundImage: `linear-gradient(120deg, rgba(255, 255, 255, 0.6) 48%, transparent 50%),url(${require('../../../assets/img/' + item.bg)})`  }}
                                
                                //top left to bottom right white shade 
                                style={{ backgroundImage: `linear-gradient(to bottom right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)),url(${require('../../../assets/img/' + item.bg)})` }}
                            >

                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-8">
                                            <div className="slider-content">
                                                <h2>{item.title}</h2>
                                                <p>{item.text}</p>
                                                <Link target="_blank" rel="noopener noreferrer" to={`${process.env.PUBLIC_URL + item.btnLink}`} className="btn btn-brand">{item.btnText}</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </SlickSlider>
        </div>
    );
}
    ;

export default Slider;